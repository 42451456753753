<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-if="logged"
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        light
        style="background: white">
      <v-list dense>
        <v-list-item-group color="#ba0c2f">
          <v-list-item
              v-for="(item, i) in items"
              :key="i"
              :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-on:click="logout">
            <v-list-item-action>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Sortir
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar v-if="logged" app class="appbarHead" clipped-left>
      <v-app-bar-nav-icon style="color: black" @click.stop=" drawer=!drawer"/>
      <v-toolbar-title class="title">
        <img src="https://www.cambralleida.org/wp-content/uploads/2019/02/Cambra-Lleida-logotip.png" style="width: 150px">
      </v-toolbar-title>
      <v-spacer/>
    </v-app-bar>
    <v-main style="background: white">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  created() {
    this.$vuetify.theme.dark = true;
    if (this.$session.exists()) {
      this.logged = true;
    } else
      this.$router.push('/login');
  },
  data: () => ({
    drawer: null,
    logged: false,
    isOpen: true,
    items: [
      {icon: 'mdi-clock-outline', text: 'Autoavaluacions pendents', route: '/'},
      {icon: 'mdi-format-list-bulleted-square', text: 'Totes les autoavaluacions', route: '/clients'},
    ],
  }),
  methods: {
    logout() {
      this.$session.destroy();
      this.$router.push('/login');
      location.reload();
    }
  }
};
</script>
<style>
.title {
  display: contents;
}

.title h1 {
  font-size: 16pt;
  color: #ba0c2f;
}

.title img {
  width: 15%;
}

.appbarHead {
  background: white !important;
  height: 70px !important;
}
</style>