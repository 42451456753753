import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'
import VueSession from "vue-session/index.esm";
import VueGlobalVariable from "vue-global-var";

Vue.use(VueAxios, axios);
Vue.use(VueSession)
Vue.config.productionTip = false
Vue.use(VueGlobalVariable, {
  globals: {
    version:"1.0",
    env_url:"https://diagnosiexport.cambralleida.org/api/"
  }
});
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
